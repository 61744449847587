import React from "react";
import { Container, Row, Col } from "react-bootstrap";
 

function Register() {
  return (
    <Container fluid className="home-about-section text-reveal" id="about">
 <a className="purple" href="https://docs.google.com/forms/d/e/1FAIpQLSfjPbnX1DxBkUqDLdjCFLWfETJIByBI3Rm2FnJJURWl4hbhHw/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Book a Session </a>  
         
    </Container>
  );
}
export default Register;
